import React from 'react';
import logo from '../../../../assets/images/logo/logo-intro.svg';
import './Intro.scss';

const Intro = () => {
  return (
    <div className="about-intro">
      <div className="left">
        <img src={logo} alt="rath-eg logo" />
      </div>
      <div className="right">
        Tutoring <br /> Academic Mentorship <br />
        Test Prep
      </div>
    </div>
  );
};

export default Intro;
