import React from 'react';

const Services = () => {
  return (
    <div className="page educators">
      This will be the Educators page. Feel free to write any content you want to see on this page and send it to me.
    </div>
  );
};

export default Services;
