import React from 'react';
import Intro from './intro/Intro.js';
import Team from './team/Team.js';
import Educators from './educators/Educators.js';
import Approach from './approach/Approach.js';

const About = ({ administrators }) => {
  return (
    <div className="page">
      <Intro />
      <Team admins={administrators} />
      <Educators />
      <Approach />
    </div>
  );
};

export default About;
