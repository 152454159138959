import React from 'react';
import Intro from './intro/Intro.js';

const Services = () => {
  return (
    <div className="page">
      <Intro />
    </div>
  );
};

export default Services;
