import React, { useState } from "react";
import eric from "../../../../assets/images/about/eric-rath.png";
import Member from "../member/Member.js";
import "./Team.scss";

const Team = ({ admins }) => {
  const evenMemberLength = admins?.length % 2 == 0;
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 768);
  let correctedAdmins = [...admins];
  if (!evenMemberLength && !isMobile) {
    correctedAdmins.splice(correctedAdmins?.length - 1, 0, { full_name: "" });
  }

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 768);
  };

  window.addEventListener("resize", checkIfMobile);

  return (
    <div className="about-team">
      <div className="left"></div>
      <div className="right">
        <div className="executive section-title">Executive team</div>
        <div className="member">
          <img src={eric} alt="rath-eg logo" className="image-circle" />
          <div className="member-info">
            <div className="member-name">Eric Rath</div>
            <div className="member-function">Founder</div>
          </div>
        </div>
        <div className="text-block member-text">
          Rath Tutoring operates on the belief that the key to student
          success with formal test prep or academic tutoring is a professional,
          experienced, and dedicated educator — one who possesses not only
          mastery over concepts and strategies but also the ability to connect
          with and motivate students. By building confidence and instilling
          progress as a habit, Rath Tutoring tutors guide students to
          meet and often exceed their standardized testing and academic goals.
        </div>
        <div
          className={`member-list ${evenMemberLength ? "even-members" : ""}`}
        >
          {correctedAdmins?.map((admin) => {
            return <Member member={admin} key={admin.full_name} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Team;
