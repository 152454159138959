import React, { useState } from 'react';
import WhoWeAre from './who_we_are/WhoWeAre.js';
import ServicesDesktop from './services_desktop/ServicesDesktop.js';
import ServicesMobile from './services_mobile/ServicesMobile.js';
import PrivacyPopup from './privacy_popup/PrivacyPopup.js';

const Home = () => {
  const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState(
    localStorage.getItem('accept-policy')
  );

  const acceptPolicy = () => {
    localStorage.setItem('accept-policy', 'true');
    closePolicy();
  };

  const closePolicy = () => {
    setHasAcceptedPolicy(true);
  };

  return (
    <div className="page">
      <WhoWeAre />
      <ServicesDesktop />
      <ServicesMobile />
      {!hasAcceptedPolicy && (
        <PrivacyPopup acceptPolicy={acceptPolicy} closePolicy={closePolicy} />
      )}
    </div>
  );
};

export default Home;
