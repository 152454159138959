import React from "react";
import handsMobile from "../../../../assets/images/services/hands-mobile.png";
import "./Intro.scss";

const Intro = () => {
  return (
    <div className="our-services">
      <div className="hands">
        <img
          className="our-services-img"
          src={handsMobile}
          alt="writing hand"
        />
      </div>
      <div className="services-text">
        <div className="our-services-title">Tutoring programs</div>
        <div className="text">
          <div className="text-block">
            Rath Tutoring tutors are available for all test prep, tutoring, and
            academic mentorship needs for American and British education
            systems.
          </div>
          <div className="text-block">
            Every Rath Tutoring program is custom tailored to each student,
            with test prep typically ranging from twelve to thirty hours for a
            single test depending on a student’s starting score, goal score, and
            timeline.
          </div>
          <div className="text-block">
            Semester-long or annual academic and mentorship programs are
            available for more comprehensive coursework.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
